import { datadogLogs } from "@datadog/browser-logs";
import CryptoJS from "crypto-js";

/**
 * @export Crypt
 * @description The Utility is responsible for the operations to encrypt and decrypt.
 */
export class Crypt {
    private key;
    // private iv;
    private mode;
    private keySize;
    private padding;

    constructor() {
        this.key = CryptoJS.SHA256(process.env.REACT_APP_ENCRYPTION_KEY ?? "");
        // this.iv = CryptoJS.SHA256(process.env.ENCRYPTION_IV ?? "");
        this.mode = CryptoJS.mode.CBC;
        this.padding = CryptoJS.pad.Pkcs7;
        this.keySize = 32;
    }


    decryptWithCryptoJS = (cipher:any,iv:any) => {
        try{
            if(!cipher){
                console.log("exection inside if of decryptWithCryptoS utility");
                return "";
            }
            const key = this.key;
            iv = CryptoJS.SHA256(iv);
            datadogLogs.logger.info("decryptWithCryptoS utility iv:", { iv });
            datadogLogs.logger.info("decryptWithCryptoS utility key:", { key });
            // const iv = this.iv;
            const plainText = CryptoJS.AES.decrypt(cipher, key, {
                keySize: this.keySize,
                iv: iv,
                mode: this.mode,
                padding: this.padding
            });
            console.log("exection inside decryptWithCryptoS utility before return statement:",plainText,"--->",plainText.toString(CryptoJS.enc.Utf8));
            return plainText.toString(CryptoJS.enc.Utf8);

        }catch(err:any){
            console.log("exection inside catch of decryptWithCryptoS utility:",err);    
            datadogLogs.logger.error("decryptWithCryptoS utility err:",{err});       
            return "";
        }
    };

}