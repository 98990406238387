import React, { useState, useRef } from "react";
import {
    Container,
    Grid,
    Box,
    Typography,
    Button,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useTranslation } from "react-i18next";
import CsvfileUpload from "../services/fileUpload";
import SimpleReactValidator from "simple-react-validator";
import { useAppDispatch } from "../../../redux/hooks";
import { commonActions } from "../../../redux/features/common/commonSlice";
import CommonButton from "../../common/Button";
import Utility from "../../../utils/Utility";

// const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
//     props,
//     ref
// ) {
//     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

const FileUpload = (props: any) => {
    
    const dispatch = useAppDispatch();
    const { handleLoader, showSnackbar } = props;
    // const [alertMessage, setAlertMessage] = useState("");
    // const [openSnackBar, setOpenSnackBar] = useState(false);
    // const [sellerInfo, setSellerInfo] = useState([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const utility = new Utility();

    const allowFiles = [
        "application/zip","multipart/x-zip","application/zip-compressed","application/x-zip-compressed",
    ];

    const [file, setFile] = useState<any>("");
    const [fileName, setFileName] = useState("");
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [resetDialogBox,setResetDialogBox] =useState(false);
    
    // const handleCloseSnackBar = () => {
    //     setOpenSnackBar(false);
    // };

    const { t } = useTranslation();
    const [, forceUpdate] = useState(0);
    const validator2 = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                max: t("Maximum 40 Character"),
                required: t("required.please_choose_file"),
            },
        })
    );

    /* File Upload api function*/
    const [formSubmit, setFormSubmit] = useState(false);
    const uploadedFile = (e: any) =>{
        setResetDialogBox(false);
        isFileUpload ?  alert(t("bulkproductupload.file_can_not_be_uploaded")) : BulkImageFileuploadData(e);
    };
    const BulkImageFileuploadData = (e: any) => {  
        dispatch(commonActions.PROGRESS_PERCENT_BULK_IMAGE_UPLOAD(0));
        const onUploadProgress = (progressEvent:any) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;  
            const progressPercent=Math.round(progress);    
            dispatch(commonActions.PROGRESS_PERCENT_BULK_IMAGE_UPLOAD(progressPercent));
        };
        dispatch(commonActions.IS_RELOAD(true));
        setFormSubmit(true);
        e.preventDefault();
        if (file) {
            dispatch(commonActions.SAVE_OPEN_PROGRESSBAR_BULK_IMAGE_UPLOAD(true));
            dispatch(commonActions.SAVE_FILE_NAME_BULK(fileName?.split("\\")[fileName?.split("\\").length-1]));
            dispatch(commonActions.PROGRESSBAR_MESSAGE_BULK_IMAGE_UPLOAD(0));
            const renamedFile = new File([file], utility.convertExtensionToLowerCase(file), { type: file.type });
            const payload = { file: renamedFile };
            const isHitDummyURL = false;
            new CsvfileUpload(payload, isHitDummyURL)
                .csvfile(onUploadProgress)
                .then((res: any) => {
                    handleLoader(false);
                    dispatch(commonActions.PROGRESSBAR_MESSAGE_BULK_IMAGE_UPLOAD(1));
                    showSnackbar(res, true);
                    setFormSubmit(false);
                    dispatch(commonActions.IS_RELOAD(false));
                    setFileName("");
                    setIsFileUpload(false);
                })
                .catch((err: any) => {
                    handleLoader(false);
                    dispatch(commonActions.PROGRESSBAR_MESSAGE_BULK_IMAGE_UPLOAD(2));
                    showSnackbar(err?.message, false ,false);
                    dispatch(commonActions.IS_RELOAD(false));
                    setFormSubmit(false);
                    setFileName("");
                    setIsFileUpload(false);
                });
            setFile("");
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        } else {
            setIsFileUpload(false);
            dispatch(commonActions.IS_RELOAD(false));
            validator2.current.showMessages();           
        }
    };
    /* File Upload api function*/
    

    const handleOnChange = (e: any) => {
        if (e.target.files.length != 0) {
            if (e.target.files[0].size < 1.024e+9) {
                if (allowFiles.includes(e.target.files[0].type)) {
                    setFile(e.target.files[0]);
                    setFileName(e.target.files[0].name);
                    setResetDialogBox(true);
                } else {
                    setFileName("");
                    showSnackbar(t("bulkproductupload.invalid_file_type"), false ,false);
                    setFile("");
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                }
            } else {
                setFileName("");
                showSnackbar(t("bulk_image_upload.file_size_1_gb"), false ,false);
                setFile("");
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            }
        } 
    };
    function clickFileButton(){
        const button=document.getElementById("file-upload-input");
        button?.click();
    }
    const handleDragOver = (event: any) => {
        event.preventDefault();
    };
    
    const handleDrop = (e: any) => {
        e.preventDefault();
        if (e.dataTransfer.files.length != 0) {
            if (e.dataTransfer.files[0].size < 1.024e+9) {
                if (allowFiles.includes(e.dataTransfer.files[0].type)) {
                    setFile(e.dataTransfer.files[0]);
                    setFileName(e.dataTransfer.files[0].name);
                    setResetDialogBox(true);
                } else {
                    setFileName("");
                    showSnackbar(t("bulkproductupload.invalid_file_type"), false ,false);
                    setFile("");
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                }
            } else {
                setFileName("");
                showSnackbar(t("bulk_image_upload.file_size_1_gb"), false ,false);
                setFile("");
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            }
        }
    };
    const resetUploadFile = () =>{
        setFileName("");
        setFile("");
        setResetDialogBox(false);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }

    };
    const replaceFileUpload = () =>{
        const button=document.getElementById("file-upload-input");
        button?.click();
    };

    return (
        <>
            <div className="main">
                <Container>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box sx={{ my: 4 }}>
                              
                            </Box>
                        </Grid>
                    </Grid>
                </Container>

                <Container>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>

                            <Box className="file-border" 
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                               
                            >
                                <Box onClick={()=>{clickFileButton();}} className="cursor-pointer">
                                    <Typography>
                                        <CloudUploadIcon
                                            className="primary download-upload-icon"                                     
                                        />
                                    </Typography>
                                    <p
                                        onChange={(e) => handleOnChange(e)}
                                    >
                                        {fileName ? fileName :""}
                                        <input
                                            id="file-upload-input"
                                            // value={fileName ?? ""} 
                                            onChange={(e)=>e.target.value}
                                            accept=".zip"
                                            className="test"
                                            type="file"
                                            ref={fileInputRef}
                                            hidden
                                        />
                                    </p>
                                    <Typography>{t("bulk_product_index.please_select_file")}</Typography>
                                    {!fileName && formSubmit ? validator2.current.message("store name", fileName, ["required"]) : null}  
                                </Box>     
                                {resetDialogBox?
                                    <Box className="file_upload_btn_box">
                                        <Button onClick={replaceFileUpload}  className="file_upload_replace_btn" variant="contained">
                                            {t("bulk_product_index.replace")}
                                        </Button>
                                        <Button onClick={resetUploadFile}  className="file_upload_reset_btn" variant="contained">
                                            {t("bulk_product_index.reset")}
                                        </Button>
                                    </Box>:""}          
                            </Box>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="upload-btn-grid">  
                                <CommonButton route={window.location.pathname} functionToExecute={uploadedFile} className="upload-btn" btnName={t("bulk_product_index.upload")} disableCondition={false}/>                                                                                                             
                                {/* <Button onClick={uploadedFile} className="upload-btn" variant="contained">
                                    {t("bulk_product_index.upload")}
                                </Button> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <Container>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box sx={{ my: 4 }} className="color-grey">
                                <Typography sx={{ pb: 2 }}>
                                    {t("bulk_image_upload.bulk_image_upload_guideline_1")}
                                </Typography>
                                <Typography>
                                    {t("bulk_image_upload.bulk_image_upload_guideline_2")}
                                </Typography>
                                <Typography>
                                    {t("bulk_image_upload.bulk_image_upload_suggestion_1")}
                                </Typography>
                                <Typography>
                                    {t("bulk_image_upload.bulk_image_upload_suggestion_2")}
                                </Typography>
                                <Typography>
                                    {t("bulk_image_upload.bulk_image_upload_suggestion_3")}
                                </Typography>
                                <Typography>
                                    {t("bulk_image_upload.bulk_image_upload_suggestion_4")}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
};

export default FileUpload;
