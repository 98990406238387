import React, { useState, useRef } from "react";
import {
    Container,
    Grid,
    Box,
    Typography,
    Button,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useTranslation } from "react-i18next";
import CsvfileUpload from "../services/fileUpload";
import SimpleReactValidator from "simple-react-validator";
import { useAppDispatch } from "../../../redux/hooks";
import { commonActions } from "../../../redux/features/common/commonSlice";
import Excel from "exceljs";
import CONSTANTS from "../../../constants/constants";
import CommonButton from "../../common/Button";
import Utility from "../../../utils/Utility";

// const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
//     props,
//     ref
// ) {
//     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

const FileUpload = (props: any) => {

    const dispatch = useAppDispatch();
    const { handleLoader, showSnackbar } = props;
    const [file, setFile] = useState<any>("");
    const [fileName, setFileName] = useState("");
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [resetDialogBox,setResetDialogBox] =useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const utility = new Utility();
    const allowFiles = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
    ];

    const { t } = useTranslation();
    const [, forceUpdate] = useState(0);
    const validator2 = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                max: t("Maximum 40 Character"),
                required: t("required.please_choose_file"),
            },
        })
    );
    /* Language translate function*/

    /* File Upload api function*/
    const [formSubmit, setFormSubmit] = useState(false);
    const uploadedFile = (e: any) =>{
        setResetDialogBox(false);
        isFileUpload ? alert(t("bulkproductupload.file_can_not_be_uploaded")) : FileuploadData(e);
    };
    
    const workSheetExcel = ()=>{
        dispatch(commonActions.SAVE_OPEN_PROGRESSBAR(false));
        dispatch(commonActions.IS_RELOAD(false));
        setIsFileUpload(false);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
        setFile("");
        setFileName(""); 
    };


    const FileuploadData = (e: any) => {
        dispatch(commonActions.PROGRESS_PERCENT_BULK_PRODUCT_UPLOAD(0)); 
        const onUploadProgress = (progressEvent:any) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;  
            const progressPercent=Math.round(progress);    
            dispatch(commonActions.PROGRESS_PERCENT_BULK_PRODUCT_UPLOAD(progressPercent));
        };
        dispatch(commonActions.IS_RELOAD(true));
        setIsFileUpload(true);
        setFormSubmit(true);
        e.preventDefault();
        if (file) {
            dispatch(commonActions.SAVE_OPEN_PROGRESSBAR(true));
            dispatch(commonActions.SAVE_FILE_NAME(fileName?.split("\\")[fileName?.split("\\").length-1]));
            dispatch(commonActions.PROGRESSBAR_MESSAGE(0));
            const renamedFile = new File([file], utility.convertExtensionToLowerCase(file), { type: file.type });
            const payload = { file: renamedFile };
            const isHitDummyURL = false;
            const workbook = new Excel.Workbook();
            workbook.xlsx.load(file)
                .then(function() {
                    const worksheet = workbook?.worksheets[0];
                    // worksheet = worksheet ? "" :  workbook?.getWorksheet(2);
                    const columnCount = worksheet?.columnCount;
                    const rowCount = worksheet?.actualRowCount;
                    const row = worksheet?.getRow(1).values;
                    const rowItem=Object.values(row);
                    
                    if(rowCount>10001){
                        showSnackbar(t("bulkproductupload.maximum_row_limit"),false,false);
                        workSheetExcel();
                    }else if(rowCount == 1){
                        showSnackbar(t("bulkproductupload.no_data_found_in_excel"),false,false);
                        workSheetExcel();
                    }else if(columnCount < 36){
                        showSnackbar(t("bulkproductupload.invalid_file_template"),false,false);
                        workSheetExcel();
                    }else{
                        let match = true;
                        CONSTANTS.SHEET_HEADER.map((item:any)=>{
                            const newRowItems = rowItem.filter((current:any)=>{
                                return current==item;
                            });
                            if(newRowItems.length !== 1){
                                match=false;
                                showSnackbar(t("bulkproductupload.invalid_file_template"),false,false);
                                workSheetExcel();
                            }
                        });

                        for (let rowNumber = 2; rowNumber <= rowCount; rowNumber++) {
                            const row = worksheet.getRow(rowNumber);
                            let isErrorFormula = false;
                            for (let colNumber = 1; colNumber <= columnCount; colNumber++) {
                                const cell = row.getCell(colNumber);
                                if (cell.formula) {
                                    const cellValue:any = cell.result;
                                    if(cellValue?.error){
                                        isErrorFormula = true;
                                        break;
                                    }
                                }
                            }
                
                            if (isErrorFormula) {
                                match=false;
                                showSnackbar(t("bulkproductupload.invalid_file_template"),false,false);
                                workSheetExcel();
                                break;
                            }
                        }
                                 
                        if(match){
                            new CsvfileUpload(payload, isHitDummyURL)
                                .csvfile(onUploadProgress)
                                .then((res: any) => {
                                    handleLoader(false);
                                    dispatch(commonActions.PROGRESSBAR_MESSAGE(1));
                                    dispatch(commonActions.IS_RELOAD(false));
                                    showSnackbar(res, true);
                                    setFormSubmit(false);
                                    setIsFileUpload(false);
                                    setFileName("");                    
                                })
                                .catch((err: any) => {
                                    handleLoader(false);
                                    setFileName("");
                                    dispatch(commonActions.PROGRESSBAR_MESSAGE(2));
                                    dispatch(commonActions.IS_RELOAD(false));
                                    showSnackbar(err?.message, false, false);
                                    setIsFileUpload(false);
                                    setFormSubmit(false);
                                });
                            setFile("");
                            if (fileInputRef.current) {
                                fileInputRef.current.value = "";
                            }
    
                        }

                    }
                });
            
        } else {
            setIsFileUpload(false);
            dispatch(commonActions.IS_RELOAD(false));
            validator2.current.showMessages();           
        }
    };

    /* File Upload api function*/

    const handleOnChange = (e:any) => {
        const file = e.target.files[0];
        if (e.target.files.length != 0) {
            if (e.target.files[0].size < 10000000) {
                if(file.type == "application/vnd.ms-excel"){
                    showSnackbar(t("bulkproductupload.invalid_file_type"), false ,false);
                    setFileName("");
                    setFile("");
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                    return; 
                }
                if (allowFiles.includes(e.target.files[0].type)) {
                    setFile(e.target.files[0]);
                    setFileName(e.target.files[0].name);
                    setResetDialogBox(true);
                } else {
                    showSnackbar(t("bulkproductupload.invalid_file_type"), false ,false);
                    setFileName("");
                    setFile("");
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                }
            } else {
                showSnackbar(t("bulkproductupload.file_size_10_mb"), false ,false);
                setFile("");
                setFileName("");
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            }
        }
    };
    
    function clickFileButton(){
        const button=document.getElementById("file-upload-input");
        button?.click();
    }

    const handleDragOver = (event: any) => {
        event.preventDefault();
    };
    
    const handleDrop = (e: any) => {
        const file = e.dataTransfer.files[0];
        e.preventDefault();
        if (e.dataTransfer.files.length != 0) {
            if (e.dataTransfer.files[0].size < 10000000) {
                if(file.type == "application/vnd.ms-excel"){
                    showSnackbar(t("bulkproductupload.invalid_file_type"), false ,false);
                    setFileName("");
                    setFile("");
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                    return; 
                }
                if (allowFiles.includes(e.dataTransfer.files[0].type)) {
                    setFile(e.dataTransfer.files[0]);
                    setFileName(e.dataTransfer.files[0]?.name);
                    setResetDialogBox(true);
                } else {
                    showSnackbar(t("bulkproductupload.invalid_file_type"), false,false);
                    setFileName("");
                    setFile("");
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                }
            } else {
                showSnackbar(t("bulkproductupload.file_size_10_mb"), false,false);
                setFileName("");
                setFile("");
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            }
        } 
    };
    const resetUploadFile = () =>{
        setFileName("");
        setFile("");
        setResetDialogBox(false);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }

    };
    const replaceFileUpload = () =>{
        const button=document.getElementById("file-upload-input");
        button?.click();
    };


    return (
        <>
            <div className="main">
                <Container>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box sx={{ my: 4 }}>
                                <Typography>
                                    {t("bulkproductupload.product_upload_message")}
                                </Typography>
                                {/* <Typography>
                                    {t("bulkproductupload.if_you_find_any_invalid")}
                                </Typography> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>

                <Container>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box className="file-border" onDragOver={handleDragOver}
                                onDrop={handleDrop}>
                                <Box onClick={()=>{clickFileButton();}} className="cursor-pointer">
                                    <Typography>
                                        <CloudUploadIcon
                                            className="primary download-upload-icon"
                                            
                                        />
                                    </Typography>
                                    <p
                                        onChange={(e) => handleOnChange(e)}
                                    >
                                        {fileName ? fileName : ""}
                                        <input
                                            id="file-upload-input"
                                            // value={fileName ?? ""} 
                                            onChange={(e)=>e.target.value}
                                            accept=".xlsx,.xls"
                                            className="test"
                                            ref={fileInputRef}
                                            type="file"
                                            hidden
                                        />
                                    </p>
                                    <Typography>{t("bulk_product_index.please_select_file")}</Typography>
                                    {!fileName && formSubmit ? validator2.current.message("store name", fileName, ["required"]) : null}    
                                </Box>                      
                                {resetDialogBox?
                                    <Box className="file_upload_btn_box">
                                        <Button onClick={replaceFileUpload}  className="file_upload_replace_btn" variant="contained">
                                            {t("bulk_product_index.replace")}
                                        </Button>
                                        <Button onClick={resetUploadFile}  className="file_upload_reset_btn" variant="contained">
                                            {t("bulk_product_index.reset")}
                                        </Button>
                                    </Box>:""}
                            </Box>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="upload-btn-grid">   
                                <CommonButton route={window.location.pathname} functionToExecute={uploadedFile} className="upload-btn" btnName={t("bulk_product_index.upload")} disableCondition={false}/>                                                    
                                {/* <Button onClick={uploadedFile} className="upload-btn" variant="contained">
                                    {t("bulk_product_index.upload")}
                                </Button> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <Container>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box sx={{ my: 4 }} className="color-grey">
                                <Typography sx={{pb:2}}>{t("product_list.instruction_no_1")}</Typography>
                                <Typography sx={{pb:2}}>{t("product_list.instruction_no_2")}</Typography>
                                <Typography sx={{pb:2}}>{t("product_list.instruction_no_3")}</Typography>
                                <Typography sx={{pb:2}}>{t("product_list.instruction_no_4")}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
};

export default FileUpload;

