import React, { useState, useRef } from "react";
import {
    Container,
    Grid,
    Box,
    Typography,
    Button
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useTranslation } from "react-i18next";
import CsvfileUpload from "../services/inventoryfileUpload";
import SimpleReactValidator from "simple-react-validator";
import { useAppDispatch } from "../../../redux/hooks";
import { commonActions } from "../../../redux/features/common/commonSlice";
import Excel from "exceljs";
import CONSTANTS from "../../../constants/constants";
import CommonButton from "../../common/Button";
import Utility from "../../../utils/Utility";

// const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
//     props,
//     ref
// ) {
//     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

const FileUpload = (props: any) => {
    const { handleLoader, showSnackbar } = props;
    // const [openSnackBar, setOpenSnackBar] = useState(false);
    const [file, setFile] = useState<any>("");
    const [fileName, setFileName] = useState<string>("");
    const [resetDialogBox,setResetDialogBox] =useState(false);
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    const allowFiles =["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
    const dispatch = useAppDispatch();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const utility = new Utility();

    // const handleCloseSnackBar = () => {
    //     setOpenSnackBar(false);
    // };
    const { t } = useTranslation();
    const [, forceUpdate] = useState(0);
    const validator2 = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                max: t("Maximum 40 Character"),
                required: t("required.please_choose_file"),
            },
        })
    );
    /* Language translate function*/

    /* Language translate function*/

    /* File Upload api function*/


    const uploadedFile = (e: any) =>{
        setResetDialogBox(false);
        isFileUpload ? alert(t("manage_inventory.file_can_not_be_uploaded")) : InventoryFileuploadData(e);
    };

    const workSheetExcel = ()=>{
        dispatch(commonActions.SAVE_OPEN_PROGRESSBAR_INVENTORY(false));
        dispatch(commonActions.IS_RELOAD(false));
        setIsFileUpload(false);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
        setFile("");
        setFileName(""); 
    };

    const InventoryFileuploadData = (e: any) => {
        dispatch(commonActions.IS_RELOAD(true));
        setIsFileUpload(true);
        setFormSubmit(true);
        e.preventDefault();
        if (file) {
            dispatch(commonActions.SAVE_OPEN_PROGRESSBAR_INVENTORY(true));
            dispatch(commonActions.SAVE_FILE_NAME_INVENTORY(fileName?.split("\\")[fileName?.split("\\").length-1]));
            dispatch(commonActions.PROGRESSBAR_MESSAGE_INVENTORY(0));
            const renamedFile = new File([file], utility.convertExtensionToLowerCase(file), { type: file.type });
            const payload = { file: renamedFile };
            const isHitDummyURL = false;
            const workbook = new Excel.Workbook();
            workbook.xlsx.load(file)
                .then(function() {
                    const worksheet = workbook?.worksheets[0];
                    const columnCount = worksheet?.columnCount;
                    const rowCount = worksheet?.actualRowCount;
                    const row = worksheet?.getRow(1).values;
                    const rowItem=Object.values(row);
                    if(rowCount>10001){
                        showSnackbar(t("bulkproductupload.maximum_row_limit"),false ,false);
                        workSheetExcel();
                    }else if(rowCount == 1){
                        showSnackbar(t("bulkproductupload.no_data_found_in_excel"), false ,false);
                        workSheetExcel();
                    }else if(columnCount < 2){
                        showSnackbar(t("bulkproductupload.invalid_file_template"),false ,false);
                        workSheetExcel();
                    }else{
                        let match = true;
                        CONSTANTS.INVENTORY_SHEET_HEADER.map((item:any)=>{
                            const newRowItems = rowItem.filter((current:any)=>{
                                return current==item;
                            });

                            if(newRowItems.length !== 1){
                                match=false;
                                showSnackbar(t("bulkproductupload.invalid_file_template"),false ,false);
                                workSheetExcel();
                            }
                        });
                        
                        if(match){
                            new CsvfileUpload(payload, isHitDummyURL)
                                .csvfile()
                                .then((res: any) => {
                                    handleLoader(false);
                                    dispatch(commonActions.PROGRESSBAR_MESSAGE_INVENTORY(1));
                                    dispatch(commonActions.IS_RELOAD(false));
                                    showSnackbar(res, true);
                                    setFormSubmit(false);
                                    setIsFileUpload(false);
                                    setFileName("");                    
                                })
                                .catch((err: any) => {
                                    handleLoader(false);
                                    setFileName("");
                                    dispatch(commonActions.PROGRESSBAR_MESSAGE_INVENTORY(2));
                                    dispatch(commonActions.IS_RELOAD(false));
                                    showSnackbar(err?.message, false,false);
                                    setIsFileUpload(false);
                                    setFormSubmit(false);
                                });
                            setFile("");
                            if (fileInputRef.current) {
                                fileInputRef.current.value = "";
                            }
                        }
                    }
                 
                });
            
        } else {
            setIsFileUpload(false);
            dispatch(commonActions.IS_RELOAD(false));
            validator2.current.showMessages();           
        }
    };

    
    /* File Upload api function*/

    const handleOnChange = (e: any) => {
        if (e.target.files.length != 0) {
            if (e.target.files[0].size < 10000000) {
                if(allowFiles.includes(e.target.files[0].type)) {
                    setFile(e.target.files[0]);
                    setFileName(e.target.files[0].name);
                    setResetDialogBox(true);

                } else {
                    setFileName("");
                    showSnackbar(t("bulkproductupload.invalid_file_type"), false , false);
                    setFile("");
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                }
            } else {
                setFileName("");
                showSnackbar(t("bulkproductupload.file_size_10_mb"), false ,false);
                setFile("");
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            }
        } 
    };
    function clickFileButton(){
        const button=document.getElementById("file-upload-input-inventory");
        button?.click();
    }
    const handleDragOver = (event: any) => {
        event.preventDefault();
    };
    
    const handleDrop = (e: any) => {
        e.preventDefault();
        if (e.dataTransfer.files.length != 0) {
            if (e.dataTransfer.files[0].size < 10000000) {
                if(allowFiles.includes(e.dataTransfer.files[0].type)) {
                    setFile(e.dataTransfer.files[0]);
                    setFileName(e.dataTransfer.files[0].name);
                    setResetDialogBox(true);

                } else {
                    setFileName("");
                    showSnackbar(t("bulkproductupload.invalid_file_type"), false ,false);
                    setFile("");
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                }
            } else {
                setFileName("");
                showSnackbar(t("bulkproductupload.file_size_10_mb"), false ,false);
                setFile("");
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            }
        } 
    };
    const resetUploadFile = () =>{
        setFileName("");
        setFile("");
        setResetDialogBox(false);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }

    };
    const replaceFileUpload = () =>{
        const button=document.getElementById("file-upload-input-inventory");
        button?.click();
    };
    return (
        <>
            <Container sx={{ my: 2 }}>
                <Box sx={{ my: 2 }}>
                    <Typography > {t("bulk_product_index.inventory_upload_message")}</Typography>
                    {/* <Typography> {t("bulk_product_index.how_to_download_a_template_and_how_to_upload_it_back_to_the_system")}</Typography> */}
                </Box>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box className="file-border" 
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                        >
                            <Box onClick={()=>{clickFileButton();}} className="cursor-pointer">
                                <Typography>
                                    <CloudUploadIcon
                                        className="primary download-upload-icon"
                                    // onChange={(e) => handleOnChange(e)}
                                    />
                                </Typography>
                                <p
                                    onChange={(e) => handleOnChange(e)}
                                >  {fileName ? fileName : ""}
                                    <input className="test" id="file-upload-input-inventory" accept=".xlsx, .xls" type="file" readOnly hidden ref={fileInputRef}/>
                                </p>
                                <Typography>{t("bulk_product_index.please_select_file")}</Typography>
                                {/* {validator2.current.message("store name", file, ["required"])} */}
                                {!fileName && formSubmit ? validator2.current.message("store name", fileName, ["required"]) : null}
                            </Box>
                            {resetDialogBox?
                                <Box className="file_upload_btn_box">
                                    <Button onClick={replaceFileUpload}  className="file_upload_replace_btn" variant="contained">
                                        {t("bulk_product_index.replace")}
                                    </Button>
                                    <Button onClick={resetUploadFile}  className="file_upload_reset_btn" variant="contained">
                                        {t("bulk_product_index.reset")}
                                    </Button>
                                </Box>:""}
                               
                        </Box>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="upload-btn-grid">       
                            <CommonButton route={window.location.pathname} functionToExecute={uploadedFile} className="upload-btn" btnName={t("bulk_product_index.upload")} disableCondition={false}/>                                                                                                                                                                 
                            {/* <Button onClick={uploadedFile} className="upload-btn" variant="contained">
                                {t("bulk_product_index.upload")}
                            </Button> */}
                        </Grid>
                    </Grid>
                </Grid>
                
            </Container>
        </>
    );
};

export default FileUpload;
