import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography, Box, IconButton, Modal, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import Marquee from "react-fast-marquee";
import { NOTIFICATION } from "../../constants/constants";

export default function NotificationMessage(props: any) {
    const { isCookiePresent, isMsgRequired, msgVisibility } = props;
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [isLongMessage, setIsLongMessage] = useState(false);
    const messageRef = useRef<HTMLDivElement>(null);
    const [textWidth, setTextWidth] = useState<number>(getTextWidth(isMsgRequired, "16px Arial"));
    const [windowWidth, getDimension] = useState<number>(window.innerWidth);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function getTextWidth(text:any, font:any) {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
    
        if (context) { 
            context.font = font || getComputedStyle(document.body).font;
            return context.measureText(text).width;
        } else {
            return 0; 
        }
    }
    
    //Dynamic message animation width calculation
    const setDimension = () => {
        getDimension(window.innerWidth);
    };
    useEffect(()=> {
        setTextWidth(getTextWidth(isMsgRequired, "15px Arial"));
        setDimension();
    },[isMsgRequired]);
    
    useEffect(() => {
        window.addEventListener("resize", setDimension);
    
        return(() => {
            window.removeEventListener("resize", setDimension);
        });
    }, [windowWidth]);
        
    const checkIfLongMessage = () => {
        if (messageRef.current) {
            const lineHeight = parseInt(getComputedStyle(messageRef.current).lineHeight);
            const maxLines = 4;
            const maxHeight = lineHeight * maxLines;
            setIsLongMessage(messageRef.current.scrollHeight > maxHeight);
        }
    };

    useEffect(() => {
        checkIfLongMessage();
        
        const handleResize = () => {
            checkIfLongMessage();
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isMsgRequired,msgVisibility]);
    const ReadMoreText = ({ text }: { text: any }) => {
        const [truncatedText, setTruncatedText] = useState(text);
        const [isTruncated, setIsTruncated] = useState(false); 
        const textRef = useRef<HTMLParagraphElement | null>(null);
    
        useEffect(() => {
            if (textRef.current) {
                truncateText();
            }
        }, [text]);
    
        const truncateText = () => {
            if (textRef.current) {
                const computedStyle = getComputedStyle(textRef.current);
                const lineHeight = parseFloat(computedStyle.lineHeight);
                const maxLines = NOTIFICATION.MAX_LINE;
                const maxHeight = lineHeight * maxLines;
                let content = text;
    
                textRef.current.innerText = content;
    
                if (textRef.current.scrollHeight > maxHeight) {
                    while (textRef.current.scrollHeight > maxHeight && content.length > 0) {
                        content = content.substring(0, content.length - 1);
                        textRef.current.innerText = content + " "; 
                    }
                    setIsTruncated(true); 
                } else {
                    setIsTruncated(false); 
                }
                setTruncatedText(content + " ");
            }
        };
    
        return (
            <Grid container >
                <Grid item xs={0.25}  sm={0.20} md={0.20} lg={0.20} xl={0.20} ></Grid>
                <Grid item xs={11.55} sm={10.15} md={10.60} lg={10.95} xl={10.95} >
                    <Grid>
                        <p style={{margin:0,whiteSpace: "pre-wrap"}} ref={textRef} className={"clampedText"}>
                            {truncatedText}
                        </p>
                    </Grid>
                </Grid>
                <Grid item xs={11.75} sm={1.65} md={1.20} lg={0.85} xl={0.85} sx={{pr:1}} >
                    <Grid>
                        {isTruncated && ( 
                            <Link
                                className="float_right system-update-read-more"
                                sx={{ 
                                    fontSize: "12px!important",
                                    marginTop: { xs: "0px", sm: "81px" }
                                }}
                                onClick={handleOpen}
                                underline="none"
                            >
                                {t("header.read_more")}
                            </Link>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <Box 
            className={` ${isCookiePresent ? "notification_msg word_wrap" : "msg_position word_wrap"}`}
            component="div"
        >
            <Grid> 
                { msgVisibility == "0" ?
                    <Typography
                        variant="inherit"
                        noWrap
                        component="div"
                        mb={1}
                    >                 
                        {textWidth > window.innerWidth ? (
                            <Marquee style={{ marginTop:"5px" }}>      
                                {isMsgRequired}   
                                <Grid component="span" style={{ display: "inline-block", width: "50px" }}></Grid>                
                            </Marquee>
                        ) : (
                            <Box style={{textAlign:"center",marginTop:"5px"}}>{isMsgRequired}</Box>
                        )}  
                    </Typography>
                    : 
                    <Box >
                        <ReadMoreText text={isMsgRequired.trim()}/>
                    </Box>
                }
                <Grid container >
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="notification-modal-title"
                        aria-describedby="notification-modal-description"
                    >
                        <Box className="center-model-information" >
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography variant="h6" component="p">
                                    {t("header.information")}
                                </Typography>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Typography 
                                id="notification-modal-description" 
                                className="notification-modal-description"
                            >
                                {isMsgRequired.trim()}
                            </Typography>
                        </Box>
                    </Modal>
                </Grid>
            </Grid>
        </Box>
    );
}